<template>
  <input
    v-model="formatvalue"
    :class="[
      'value-container',
      {
        placeholder: !value,
      },
    ]"
    type="text"
    :placeholder="placeholder"
  />
</template>

<script>
import { debounce } from '../config';
export default {
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
  },
  //组件注册
  data() {
    return {};
  },
  //计算属性
  computed: {
    formatvalue: {
      get() {
        return this.value;
      },
      set(val) {
        this.updateValue(val);
      },
    },
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    updateValue: debounce(function (val) {
      this.$emit('set:value', val);
    }),
  },
};
</script>

<style lang="scss" scoped>
.value-container {
  flex: 1;
  min-height: 40px;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  color: #53bcff;
  font-size: 16px;
}
.placeholder {
  &::-webkit-input-placeholder {
    font-size: 16px;
    color: #699bc6;
  }
}
</style>
